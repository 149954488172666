import { useMutation, useQuery } from "@tanstack/react-query";
import { GetCommanWay, ListCommanWay } from "API/commonApi";
import { Img } from "components";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import "react-circular-progressbar/dist/styles.css";
import { isMobile } from "react-device-detect";
import { Link, useLocation, useParams } from "react-router-dom";
import UseToast from "utils/AfToast";
import Spinner from "utils/Spinner";

const NewsListSingleBlock = ({ props }) => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const n = props;
  return (
    <div key={n.slug} className="bg-[#FFFCF1] rounded-2xl p-4 border border-[#D9D3C5]">
      <Link to={(n.isSeason ? "/season/" : "/news/") + n.slug}>
        <Img src={BASE_URL + n.image} className="rounded-t-xl" />
        <p className="mt-5 font-bold">{n.title}</p>
      </Link>
      <p
        className="font-medium text-[#6B6B6B] mt-3  text-ellipsis overflow-hidden "
        style={{ display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical" }}
      >
        {n.description}
      </p>
      <div className="flex justify-between mt-4">
        <span className="font-medium uppercase">{n._subCategory ? n._subCategory.name : n._mainCategory.name}</span>
        <span className="flex justify-between items-center gap-1 font-medium">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9998 20.2697C15.5298 20.2697 18.8198 18.1897 21.1098 14.5897C22.0098 13.1797 22.0098 10.8097 21.1098 9.39973C18.8198 5.79973 15.5298 3.71973 11.9998 3.71973C8.46984 3.71973 5.17984 5.79973 2.88984 9.39973C1.98984 10.8097 1.98984 13.1797 2.88984 14.5897C5.17984 18.1897 8.46984 20.2697 11.9998 20.2697Z"
              stroke="#3F2E13"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
              fill="#3F2E13"
              stroke="#3F2E13"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {n.views}
        </span>
      </div>
      <div className="flex items-center self-end justify-end gap-2 mt-2">
        <a className="text-primary font-medium text-sm">Share</a>
        <a
          target="_blank"
          className="cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(BASE_URL + "/share/news/" + n.slug);
          }}
        >
          <Img src="/link.png" className="h-7 border border-secondary rounded-full" />
        </a>
        {isMobile ? (
          <a
            href={`https://api.whatsapp.com/send?text=${BASE_URL + "/share/news/" + n.slug}`}
            target="_blank"
          >
            <Img src="/wapp.png" className="h-7 border border-secondary rounded-full" />
          </a>
        ) : (
          <a
            href={`https://web.whatsapp.com/send?text=${BASE_URL + "/share/news/" + n.slug}`}
            target="_blank"
          >
            <Img src="/wapp.png" className="h-7 border border-secondary rounded-full" />
          </a>
        )}
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_URL + "/share/news/" + n.slug}`}
          target="_blank"
        >
          <Img src="/fb.png" className="h-7" />
        </a>
        <a
          href={`https://x.com/intent/tweet?text=${n.title} ${BASE_URL + "/share/news/" + n.slug}`}
          target="_blank"
        >
          <Img src="/x.png" className="h-7" />
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${
            window.location.origin + (n.isSeason ? "/season/" : "/news/") + n.slug
          }&title=${n.title}`}
          target="_blank"
        >
          <Img src="/linkedin.png" className="h-7" />
        </a>
      </div>
    </div>
  );
};
export default NewsListSingleBlock;
